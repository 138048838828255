

import wingslogo from '../assets/wingslogo.png'
import header from '../assets/header.png'
import headerimg from '../assets/header-img.png'
import menuitem from '../assets/menuitem.png'
import headerimg2 from '../assets/headerimage2.png'
// import sunflower from '../assets/sunflower.jpg'
import chickenwings from '../assets/chicken-wings.jpg'
import onionrings from '../assets/onionrings.jpg'
import burgers from '../assets/burger.jpg'
import menupageheader from '../assets/menupage-header.jpg'
import slider1 from '../assets/slider1.jpg'
import slider2 from '../assets/slider2.jpg'
import slider3 from '../assets/slider3.jpg'
import slider4 from '../assets/slider4.jpg'
import slider5 from '../assets/slider5.jpg'
import slider6 from '../assets/slider6.jpg'
import combos from '../assets/combos.jpg'
import justwings from '../assets/justwings.jpg'
import partypacks from '../assets/partypacks.jpg'
import hamburger from '../assets/hamburger.jpg'
import jalapenocheeseburger from '../assets/jalapenocheeseburger.jpg'
import smokeybbq from '../assets/smokybbqburger.jpg'
import doublecheeseburger from '../assets/doublecheeseburger.jpg'
import chickensandwich from '../assets/chickensandwich.jpg'
import kidsmeal from '../assets/kidsmeal.jpg'
import friedjalapeno from '../assets/friedjalapeno.jpg'
import crispyonionrings from '../assets/crispyonionrings.jpg'
import cornnuggets from '../assets/cornnuggets.jpeg'
import freshfries from '../assets/freshfries.jpg'
import cheesysticks from '../assets/cheesysticks.jpg'
import buffaloranchfries from '../assets/buffaloranchfries.jpg'
import coleslaw from '../assets/coleslaw.jpg'
import garlicparmfries from '../assets/garlicparmfries.jpg'
import carrotsandcelery from '../assets/carrotsandcelery.jpg'
import cheesecakedippers from '../assets/cheesecakedippers.jpg'
import ranch from '../assets/ranch.jpg'
import bleucheese from '../assets/bleucheese.jpg'
import cansoda from '../assets/cansoda.jpg'
import logo from '../assets/logo.png'

export default {

  wingslogo,
  header,
  headerimg,
  menuitem,
  headerimg2,
  // sunflower
  chickenwings,
  onionrings,
  burgers,
  menupageheader,
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  combos,
  justwings,
  partypacks,
  chickensandwich,
  doublecheeseburger,
  smokeybbq,
  hamburger,
  jalapenocheeseburger,
  kidsmeal,
  friedjalapeno,
  crispyonionrings,
  cornnuggets,
  freshfries,
  cheesysticks,
  buffaloranchfries,
  coleslaw,
  garlicparmfries,
  carrotsandcelery,
  cheesecakedippers,
  ranch,
  bleucheese,
  cansoda,
  logo

};
