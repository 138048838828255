import React from 'react'
import './ContactForm.css'
import {BsFillTelephoneFill,BsFillCheckCircleFill} from 'react-icons/bs'
import {BiTime} from 'react-icons/bi'

import {MdHome, MdEmail} from 'react-icons/md'
import emailjs from '@emailjs/browser';
import PopUpBox from '../PopUpBox/PopUpBox'

const ContactForm = () => {
  const [formValues, setFormValues] = React.useState({
    from_name: '',
    user_email: '',
    message: ''
  });
  const [showMessage, setShowMessage] = React.useState(false);
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const form = React.useRef();



  const handleClick = () => {
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000); // hide after 3 seconds
  };

  const openPopUp = () =>{
    setShowPopUp(true)
  }
  const closePopUp = () => {
    setShowPopUp(false);
  }

 

  const sendEmail = (e) => {
    e.preventDefault();
    if (formValues.from_name === '' || formValues.user_email === '' || formValues.message === '') {
      console.log("hellooo")
      setShowError(true);
    } else {
      setShowError(false);
      emailjs.sendForm('service_tce2wpk', 'template_m1bargr', form.current, 'VXqsnLIqUHNYtt7go')
        .then((result) => {
            
            setFormValues({from_name: '',
            user_email: '',
            message: ''})
            e.target.reset()
            handleClick()   
        }, (error) => {
            // console.log(error.text);
        });
      // console.log('Form submitted!');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const email = "wingstation.riverstone4821@gmail.com"; 
  const phoneNumber = "(832) 987-1343"; 
  
  const openEmail = () =>{
    // window.location.href = `mailto:${email}`;  
    window.open( `mailto:${email}`, "_blank");  
  }

  const openPhone = () =>{
    window.location.href = `tel:${phoneNumber}`;
  }
  const checkAddress = () =>{
    window.open("https://goo.gl/maps/X72TuBwqnEDHKhAc8", "_blank");    
  }
  return (
    <div className='contact-form-section'>
      {/* Contact Form */}

      
      {showPopUp && 
      <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1,
        
      }}
    >
      <PopUpBox className='pop-up-box-div' onClose={closePopUp} isOpen={showPopUp}/>
      </div>
      }
      
      <div className="side-tabs">
        <div className="first-tab tabs" onClick={openPhone}><BsFillTelephoneFill color='#1d1c1b' fontSize={20}/> <p>Phone: (832) 987-1343</p> </div>
        <div className="second-tab tabs" onClick={openEmail}><MdEmail color='#1d1c1b' fontSize={20}/> <p>Email: wingstation.riverstone4821@gmail.com</p></div>
        <div className="third-tab tabs" onClick={openPopUp}><BiTime color='#1d1c1b' fontSize={20}/> <p>Check Timings</p></div>
        <div className="third-tab tabs" onClick={checkAddress}><MdHome color='#1d1c1b' fontSize={20}/> <p>4821 LJ Parkway suite#80 Sugarland TX 77479</p></div>

      </div>
      

      <div className='middle-border'></div>
      <div className="contact-form">

      <form ref={form} onSubmit={sendEmail}>
        <div className="name-section form-section">
        <p>Name</p>
        <input type="text" name='from_name' value={formValues.name} onChange={handleInputChange}/>

        </div>
        <div className="email-section form-section">
        <p>Email</p>
        <input type="email" name='user_email' value={formValues.email} onChange={handleInputChange}/>

        </div>
        <div className="message-section">
        <p>Message</p>
        {/* <input type="text" /> */}
        <textarea name="message" id="" cols="60" rows="5" value={formValues.message} onChange={handleInputChange}></textarea>
        </div>
        <div className="submit-button">

        <input type="submit" value="Send" className='btn'/>
        </div>
        </form>
        {showMessage && 
        
        <h3 className='success-message'>Message sent successfully! <BsFillCheckCircleFill/> </h3>
        }

        {showError &&
        
        <h3 style={{"color":"red", "fontFamily":"var(--font-inter)", "fontSize":"14px"}}>Please fill the entire form to send a message</h3>
        }
      </div>
    </div>
  )
}

export default ContactForm
