import React from 'react'
import './FlavourCard.css'

const FlavourCard = (props) => {
  return (
    <div className='card-outer-section'>
      

      <button className={`flavour-card-button ${props.flavourcolor}`}>{props.flavourname}</button>
    </div>
  )
}

export default FlavourCard
