import React from 'react';
import { SubHeading } from '../../../components';
import {GiForkKnifeSpoon} from 'react-icons/gi'
import images from '../../../constants/images';

import './Intro.css';

const Intro = () => (
  <div className='menu-intro'>
    <img src={images.logo} alt="" />
    <SubHeading
      title = "MENU"
    />
    <div className="border-divs">

    <div className='left-div'>

    </div>
    <GiForkKnifeSpoon color='#FF5623' className="fork-icon"/>
    <div className='right-div'>

    </div>
    </div>
  </div>
);

export default Intro;
