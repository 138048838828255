import React from 'react';
import {AiOutlineArrowRight, AiFillInstagram} from 'react-icons/ai'
import {FaFacebook, FaTiktok} from 'react-icons/fa'
import { useNavigate } from "react-router-dom";


import './Footer.css';

const Footer = (props) => {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/menu`; 
    navigate(path);
  }
  const facebookLink = () =>{
    window.open("https://web.facebook.com/wingstationriverstone/?_rdc=1&_rdr", "_blank");    
  }
  const instagramLink = () =>{
    window.open("https://instagram.com/wing_station_riv?igshid=YmMyMTA2M2Y=", "_blank");    
  }
  const tiktokLink = () =>{
    window.open("https://www.tiktok.com/@wingstationriverston4821", "_blank");    
  }
  const gotoHome = () =>{ 
    let path = `/`; 
    navigate(path);
  }

  return(

  <div className={props.page === "contact" ? 'footer-section image' :  props.page === "menu" ? 'footer-section menu-color' : 'footer-section color'}>
    <div className="inner-footer-section">
    <div className='header-div'>
      <h1 onClick={gotoHome}>WingStation</h1>
      <p>©2023 Wing Station </p>
      
      </div>

      <div className='socials-icons'>
        <FaFacebook color="#FF5623" fontSize={30} className="social-icon" onClick={facebookLink}/>
        <AiFillInstagram color="#FF5623" fontSize={30} className="insta-icon social-icon" onClick={instagramLink}/>
        <FaTiktok color="#FF5623" fontSize={30} className="social-icon" onClick={tiktokLink}/>
      </div>

    </div>
    {/* <div className="footer-1">
      <div className='header-div'>
      <h1>WingStation</h1>

      <button>We Cater <AiOutlineArrowRight /></button>
      </div>
    
      <div className="locations-div">
        <p>Locations: </p>
        <a href="">Riverstone – (832) 987-1343</a>
       
      </div>

      <div className="buttons-div">

      <button className='button-1' onClick={routeChange}>Explore Menu <AiOutlineArrowRight /></button>
      <button className='button-2'>Contact</button>
      </div>

    </div>
    <div className="footer-2">
      <div className='socials-link'>
        <a href="">Visit our socials now!</a>
      </div>
      <div className='socials-icons'>
        <FaFacebook color="#FF5623" fontSize={40} className="social-icon"/>
        <AiFillInstagram color="#FF5623" fontSize={40} className="insta-icon social-icon"/>
        <FaTiktok color="#FF5623" fontSize={40} className="social-icon"/>
      </div>
    </div>
    <div className="footer-3">
      <p>©2023 Wing Station </p>
      <a href="">Terms</a>
      <a href="">Privacy</a>
      <a href="">Sitemap</a>
    </div> */}
  </div>
  )
}


export default Footer;
