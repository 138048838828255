import { initializeApp} from "firebase/app"; 
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "wingstation-project.firebaseapp.com",
    projectId: "wingstation-project",
    storageBucket: "wingstation-project.appspot.com",
    messagingSenderId: "850486444784",
    appId: "1:850486444784:web:d128d9aaefe224f57ff835",
    measurementId: "G-WM55S4JHT0"
  };
  
  
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const db = getFirestore();

