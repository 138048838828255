import React from 'react'
import {Navbar, CTA, MenuCard, MenuNavbar}  from '../../components'
import Footer from '../HomePage/Footer/Footer'
import Gallery from './Gallery/Gallery'
import Intro from './Intro/Intro'
// import Login from './Login/Login'

// import './MenuPage.css'
const MenuPage = () => {
  return (
    <div>
        <Navbar/>
        <Intro/>
         <MenuNavbar/>
         <Gallery/>
        <Footer page="menu"/>

    </div>
  )
}


export default MenuPage;