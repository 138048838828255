import React from 'react';
import './MenuNavbar.css'

const MenuNavbar = (props) => {
    // const [isSticky, setIsSticky] = useState(false);
    function scrollToSection(event) {
        event.preventDefault();
      
        const sectionId = event.target.getAttribute("href");
      
        const section = document.querySelector(sectionId);
      
        section.scrollIntoView({ behavior: "smooth" });
      }

//   useEffect(() => {
//     function handleScroll() {
//       const navbar = document.getElementById('navbar');
//       const sticky = navbar.offsetTop;
//       if (window.pageYOffset >= sticky) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     }

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
  return (
    <div className='outer-navbar-section'>
        
        <div className="inner-navbar-section" id='navbar'>
            <ul className='navbar-ul'>
                <li className='navbar-li'><a href="#personalCombos" onClick={scrollToSection}>Personal Combos</a></li>
                <li className='navbar-li'><a href="#justWings" onClick={scrollToSection}>Just Wings</a></li>
                <li className='navbar-li'><a href="#partyPacks" onClick={scrollToSection}>Party Packs</a></li>
                <li className='navbar-li'><a href="#burgers" onClick={scrollToSection}>Burgers</a></li>
                <li className='navbar-li'><a href="#kidsMeal" onClick={scrollToSection}>Kids Meal</a></li>
                <li className='navbar-li'><a href="#appsSides" onClick={scrollToSection}>Apps & Sides</a></li>
                <li className='navbar-li'><a href="#desserts" onClick={scrollToSection}>Desserts</a></li>
                <li className='navbar-li'><a href="#drinks" onClick={scrollToSection}>Drinks</a></li>
                <li><a href="#dips" onClick={scrollToSection}>Dips</a></li>
            </ul>
            
        </div>
        
    </div>
  )
}

export default MenuNavbar
