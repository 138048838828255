import React from 'react';

import AboutUs from './AboutUs/AboutUs'
import Header from './Header/Header'
import Laurels from './Laurels/Laurels'
import Footer from './Footer/Footer'
import {Navbar, CTA, MenuCard}  from '../../components'
import './HomePage.css'

// import './App.css';

const HomePage = () => (
  <div>
    <Navbar/>
    <Header />
    <AboutUs />
    <Laurels id="location-section"/>
    <CTA/>
    <Footer page="home"/>
  </div>
);

export default HomePage;