import images from './images';

const timings = [
  {
    day: "Monday - Thursday",
    start: "11am",
    end: "8:30pm"
  },
  {
    day: "Friday",
    start: "11am",
    end: "9pm"
  },
  {
    day: "Saturday",
    start: "12am",
    end: "9pm"
  },
  {
    day: "Sunday",
    start: "12am",
    end: "8:30pm"
  },
]

const menuitems = [
  
  {
    id:1,
    imgUrl: images.onionrings,
    title: 'Onion Rings',
    
  },
  {
    id:2,
    imgUrl: images.chickenwings,
    title: 'Chicken Wings',

  },
  {
    id:3,
    imgUrl: images.burgers,
    title: 'Burger',
    
  },
]


export default {  menuitems, timings };
