import React from 'react';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
// import GoogleMapReact from 'google-map-react';
import { useNavigate } from "react-router-dom";

import { GoogleMap, Marker,LoadScript  } from '@react-google-maps/api';
import {FaMapMarkerAlt} from 'react-icons/fa'
import './Laurels.css';

const MarkerComponent = () =><FaMapMarkerAlt color='var(--color-orange)' fontSize={30}/>;

const containerStyle = {
  width: '100%',
  height: '300px'
};

const Laurels = () => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `menu`; 
    navigate(path);
  }
  const newRouteChange = () =>{
    let path = `contact`;
    navigate(path)
  }

  const defaultProps = {
    center: {
      lat: 29.55223,
      lng: -95.58734
    },
    zoom: 18
  };

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  
  return(

  <div className='main-map-container' id='location-section' >
    {/* Laurels */}
    {/* <h1>Hello??</h1> */}
    <div className='map-heading'>
      <h3>Check out our location now!</h3>
    </div>
    <div className='outer-container'>
      <div  className='map-container'>

      <LoadScript
        googleMapsApiKey= "AIzaSyA1PPLv5SRt2zFMo4mLHUcfDznmh5eJQR8"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultProps.center}
          zoom={19}
        >
           <Marker
            icon={MarkerComponent}
            position={defaultProps.center}
            title= "4821 LJ Pkwy #80"
          />
        </GoogleMap>
      </LoadScript>
      </div>

      <div className='map-desc-container'>
        <p className='location-text'>4821 LJ Parkway suite#80 Sugarland TX 77479</p>
      
      </div>

    </div>

    <div className="map-buttons">
    <button className='app__header-button-2' onClick={newRouteChange}>Contact</button>
    </div>
    
      {/* <h2>ok hi</h2> */}
  </div>
  )
}


export default Laurels;
