import React from 'react';
import { ItemCard } from '../../../components/ItemCard/ItemCard';
import {AiOutlineArrowRight} from 'react-icons/ai'
import data from '../../../constants/data'
import { useNavigate } from "react-router-dom";




import './AboutUs.css';

const AboutUs = (props) => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `menu`; 
    navigate(path);
  }

  
  const cards = data.menuitems.map(item => {
    return(
      <ItemCard
        key= {item.id}
        img = {item.imgUrl}
        itemname = {item.title}
        
      />
    )

  })
  // let navigate = useNavigate(); 
  // const routeChange = () =>{ 
  //   let path = `menu`; 
  //   navigate(path);
  // }

  return(
  <div className='about_us_section section__padding' >
   <h1 className='about_us_heading'>From hand spun chicken wings to juicy burgers! We’ve got ‘em all for you!</h1>

   <div className="menu-items">
    {cards}

   </div>

   <div className='menu-button'>
   <button className='aboutus__header-button-1' onClick={routeChange}>Explore Menu <AiOutlineArrowRight /></button>

   </div>
  </div>
  )
}

export default AboutUs;
