import React from 'react'
import './PopUpBox.css'
import {MdOutlineRestaurantMenu} from 'react-icons/md'
import data from '../../constants/data'

const PopUpBox = (props) => {
    const [close, setClose] = React.useState(false)
    const closePopUp = () =>{
        // setClose(true)
        // if(props.isOpen){
        //     setClose(true)
        // }
        // else{
        //     setClose(false)
        // }
        props.onClose();
    }
    const cards = data.timings.map(item => {
        return(
            <div className='modal-pop-up-tab'>
            <p className='pop-up-tab-text'>
                {item.day} {item.start} - {item.end}
            </p>
        </div>
        )
    
      })
  return (
    <div className='modal-pop-up'>
    <MdOutlineRestaurantMenu className='modal-pop-up-close' onClick={closePopUp}/>
    <div className='inner-pop-up'>

    <h2 className='modal-pop-up-heading'>Timings</h2>
    {/* <div className='modal-pop-up-tab'>
        <p className='pop-up-tab-text'>
            {data.timings[0].day} {data.timings[0].start} - {data.timings[0].end}
        </p>
    </div> */}
    {cards}
    </div>
    </div>
  )
}

export default PopUpBox
