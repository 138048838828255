import React from 'react';
import { ContactForm, EmploymentForm } from '../../../components';
import './FindUs.css'

const FindUs = () => {
  const [toggleState, setToggleState] = React.useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return(

  <div className='contact-section'>
    <div className="inner-form">

    {/* <p>FindUs</p> */}
    {/* <ul className='inner-form-links'>
      <li className='p__opensans'>Contact</li>
      <li className='p__opensans'>Employment</li>
    </ul> */}
    {/* <div className="bloc-tabs">
        <button
          className={toggleState === 1 ? "navtabs active-tabs" : "navtabs"}
          onClick={() => toggleTab(1)}
        >
          Contact
        </button>
        <button
          className={toggleState === 2 ? "navtabs active-tabs" : "navtabs"}
          onClick={() => toggleTab(2)}
        >
          Employment
        </button>
        
      </div> */}
    

    {/* <div className={toggleState === 1 ? "content  active-content" : "content"}> */}
    <h1>Contact Us</h1>
      <p className='contact-page-tagline'>Feel free to send us your messages and questions!</p>
    <ContactForm/>
    {/* </div> */}

    {/* <div  className={toggleState === 2 ? "content  active-content" : "content"}>

    <EmploymentForm/>
    </div> */}
      
    </div>
  </div>
  )
}


export default FindUs;
