import React from 'react'

import './ItemCard.css'
// import images from '../../../constants/images'


export const ItemCard = (props) => {
  const gotoMenu = () => {
    if(props.itemname === "Onion Rings"){

      window.location.href = "/menu#section";
    }
    else if(props.itemname === "Chicken Wings"){
      window.location.href = "/menu#section2"
    }
    else if(props.itemname === "Burger"){
      window.location.href = "/menu#section3"
    }
  };
  return (
    <div className='outer-card' onClick={gotoMenu}>
        {/* `../images/${props.img}` */}
        <img src={`${props.img}`} alt="" className='menu-item-image'/>
        <div  className='menu-item-name'>

        <p>{props.itemname}</p>
        </div>
    </div>
  )
}
