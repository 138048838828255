import React, { useState, useEffect } from 'react';
import './MenuCard.css'
import images from '../../constants/images'

const MenuCard = (props) => {
  const [isConditionTrue, setIsConditionTrue] = useState("");
  const [imageSrc, setImageSrc] = useState('');


  useEffect(() => {
    
    if(props.menu_type === "combos" || props.menu_type === "wings" || props.menu_type === "partypacks"){

      setIsConditionTrue("combos");
    }
    else if(props.menu_type === "burgers"){
      const src = ``;
      setImageSrc(src);
      setIsConditionTrue("burgers")
    }
    else if(props.menu_type === "kidsmeal"){
      setIsConditionTrue("kidsmeal")
    }
    else if(props.menu_type === "appsides"){
      setIsConditionTrue("appsides")
    }
    else if(props.menu_type === "desserts"){
      setIsConditionTrue("desserts")
    }
    else if(props.menu_type === "dips"){
      setIsConditionTrue("dips")
    }
    else if(props.menu_type === "drinks"){
      setIsConditionTrue("drinks")
    }

  });
  return (
    
    <div className='menucard-outer'>
    {
      isConditionTrue === "combos" ? 
        <div className={props.menu_type === "wings" ? 'menucard-inner wings-height' : 'menucard-inner combo-height'}>
        <div className="menu-image">
          {props.menu_type === "combos" ?
          
            <img src={images.combos} alt="" /> : ""
          }
          {props.menu_type === "wings" ?
          
            <img src={images.justwings} alt="" /> : ""
          }
          {props.menu_type === "partypacks" ?
          
            <img src={images.partypacks} alt="" /> : ""
          }
          
        </div>

    <div className="menu-desc">
    <p className='combo-desc'>
   {props.description}
    </p>
    <br />
    <p className='combo-price'>
    ${props.price}
    </p>
    
    
    {/* : 
    <p className='combo-desc'>
    {props.description.number_of_sandwich} SANDWICH + {props.description.number_of_drink} DRINK
    & {props.description.number_of_fries} FRESH CUT FRIES 
    </p> */}
     
    </div>
    </div>
    : ""
    
  }
  {
    isConditionTrue === "burgers" ?
    
    <div className='burger-height'>
        <div className="burger-image">
            <img src={images[props.img]} alt="" />
        </div>

    <div className="burger-desc-container">
 
      <p className='burger-name'>
        {props.name}
      </p>
       <p className='combo-desc'>
       {props.description}
        </p>

    </div>
    </div>:  "" 
      
    }
    {
    isConditionTrue === "kidsmeal"? 
    <div className="menucard-inner kidsmeal-height">
        <div className="menu-image">
            <img src={images.kidsmeal} alt="" />
        </div>

    <div className="menu-desc">
    <p className='burger-desc'>
   {props.description}
    </p>
    
    
    <p className='combo-price'>
    ${props.price}
    </p>
      
      
    {/* : 
    <p className='combo-desc'>
    {props.description.number_of_sandwich} SANDWICH + {props.description.number_of_drink} DRINK
    & {props.description.number_of_fries} FRESH CUT FRIES 
    </p> */}
     
    </div>
    </div>: ""
}
{
    isConditionTrue === "appsides" ? 
    <div className="menucard-inner kidsmeal-height">
        <div className="menu-image">
            <img src={images[props.img]} alt="" />
        </div>

    <div className="menu-desc">
    <p className='burger-desc'>
   {props.description}
    </p>
      {
        props.large_price  ?
      <div style={{"display" : "flex"}}>
    <p style={{"marginRight":"10px"}}>LARGE</p>
    <p className='combo-price'>
    ${props.large_price}
    </p>
      </div>:""
      }
      {
        props.small_price  ?
      <div style={{"display" : "flex", "justifyContent" : "space-between"}}>
    <p style={{"margin-right":"10px"}}>SMALL</p>
    <p className='combo-price'>
    ${props.small_price}
    </p>
      </div>: ""
      }

    
    
    
    {/* : 
    <p className='combo-desc'>
    {props.description.number_of_sandwich} SANDWICH + {props.description.number_of_drink} DRINK
    & {props.description.number_of_fries} FRESH CUT FRIES 
    </p> */}
     
    </div>
    </div>: ""
}

{
    isConditionTrue === "desserts" ? 
    <div className="menucard-inner kidsmeal-height">
        <div className="dessert-image">
            <img src={images[props.img]} alt="" />
        </div>

    <div className="menu-desc">
    <p className='burger-desc'>
   {props.description}
    </p>
      
     
    <p className='combo-price'>
    ${props.price}
    </p>
      

    
    
    
    {/* : 
    <p className='combo-desc'>
    {props.description.number_of_sandwich} SANDWICH + {props.description.number_of_drink} DRINK
    & {props.description.number_of_fries} FRESH CUT FRIES 
    </p> */}
     
    </div>
    </div>: ""
}

{
    isConditionTrue === "dips" ? 
    <div className="menucard-inner kidsmeal-height">
        <div className="menu-image">
            <img src={images[props.img]} alt="" />
        </div>

    <div className="menu-desc">
    <p className='burger-desc'>
   {props.description}
    </p>
      
     {/* <p>{props.amount}</p>
    <p className='combo-price'>
    ${props.price}
    </p> */}
      

    
    
    
    {/* : 
    <p className='combo-desc'>
    {props.description.number_of_sandwich} SANDWICH + {props.description.number_of_drink} DRINK
    & {props.description.number_of_fries} FRESH CUT FRIES 
    </p> */}
     
    </div>
    </div>: ""
}
{
    isConditionTrue === "drinks" ? 
    <div className="menucard-inner kidsmeal-height">
        <div className="menu-image">
            <img src={images[props.img]} alt="" />
        </div>

    <div className="menu-desc">
    <p className='burger-desc'>
   {props.description}
    </p>
      
     
    <p className='combo-price'>
    ${props.price}
    </p>
      

    
    
    
    {/* : 
    <p className='combo-desc'>
    {props.description.number_of_sandwich} SANDWICH + {props.description.number_of_drink} DRINK
    & {props.description.number_of_fries} FRESH CUT FRIES 
    </p> */}
     
    </div>
    </div>: ""
}
  </div> 
  )
}

export default MenuCard
