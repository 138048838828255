import React from 'react';
import './SubHeading.css'

const SubHeading = (props) => (
  <div className='subheading-div'>
    <p className='subheading'>{props.title}</p>
  </div>
);

export default SubHeading;
