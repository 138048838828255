import React from 'react';

import { HomePage,MenuPage,ContactPage} from './container';
import {
  BrowserRouter,
  Routes,
  Route,
  
} from "react-router-dom";

import './App.css';


function App () {
  
  return(
    <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="menu" element={<MenuPage/>}/>
          <Route path="contact" element={<ContactPage/>}/>

          {/* <RedirectFunction from="/*" to="/" /> */}
          
      </Routes>
    </BrowserRouter>
    
    )
}

export default App;
