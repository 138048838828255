import React, { useState, useRef } from "react";
import FlavourCard from '../../../components/FlavourCard/FlavourCard';
// import MenuCard from '../../../components/MenuCard/MenuCard'
import {MenuCard} from '../../../components'
import {BsFillArrowUpCircleFill} from 'react-icons/bs'
// import firebase from 'firebase/app';
import 'firebase/firestore';
import './Gallery.css';


import {db} from '../../../firebase-config'
import {collection, getDocs} from 'firebase/firestore'



const Gallery = () => {
  const [flavours, setFlavours] = React.useState([]);
  const [combos, setCombos] = React.useState([]);
  const [wingscombos, setWingCombos] = React.useState([]);
  const [partyPacks, setPartyPacks] = React.useState([]);
  const [sides, setSides] = React.useState([]);
  const [burgerMenu, setBurgerMenu] = React.useState([]);
  const [kidsMeal, setKidsMeal] = React.useState([]);
  const [desserts, setDesserts] = React.useState([]);
  const [dips, setDips] = React.useState([]);
  const [drinks, setDrinks] = React.useState([]);

  const flavorsCollectionRef = collection(db, "wingstation-flavors");
  const combosCollectionRef = collection(db, "combos");
  const wingscombosCollectionRef = collection(db, "wings-combos");
  // const wingscombosCollectionRef = getDocs(orderBy(collection(db, "wings-combos"), 'combo_price', 'asc'))
  const partypacksCollectionRef = collection(db, "party-packs");
  const sidesCollectionRef = collection(db, "apps-sides");
  const burgermenuCollectionRef = collection(db, "burger-menu");
  const kidsmealCollectionRef = collection(db, "kids-meal");
  const dessertsCollectionRef = collection(db, "desserts");
  const dipsCollectionRef = collection(db, "dips");
  const drinksCollectionRef = collection(db, "soft-drinks");


  const getDrinks = async()=>{
    const data = await getDocs(drinksCollectionRef);
    setDrinks(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })));
  }

  const getDips = async ()=>{
    const data = await getDocs(dipsCollectionRef);
    setDips(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })));
  }

  const getDesserts = async()=>{
    const data = await getDocs(dessertsCollectionRef);
    setDesserts(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })));
  }

  const getKidsMeal = async()=>{
    const data= await getDocs(kidsmealCollectionRef);
    setKidsMeal(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })));
  }

  const getBurgerMenu = async() =>{
    const data = await getDocs(burgermenuCollectionRef);
    setBurgerMenu(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })));
  }

  const getAppsSides = async()=>{
    const data = await getDocs(sidesCollectionRef);
    setSides(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })));
    
  }

  const getPartyPacks = async () =>{
    const data = await getDocs(partypacksCollectionRef);
    setPartyPacks(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })).sort((a, b) => a.combo_price - b.combo_price));
  }

  const getWingsCombos = async()=>{
    
    const data = await getDocs(wingscombosCollectionRef);
    // const data = await getDocs(orderBy (wingscombosCollectionRef, "combo_desc"))
    setWingCombos(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })).sort((a, b) => a.combo_price - b.combo_price));
    // console.log("kashish fatima",wingscombos)
  }


  const getCombos = async() =>{
    const data = await getDocs(combosCollectionRef);
    // console.log(data.docs.map((doc)=>({
    //   ...doc.data(),id:doc.id
    // })));

    setCombos(data.docs.map((doc)=>({
      ...doc.data(),id:doc.id
    })).sort((a, b) => a.combo_price - b.combo_price));
  }


  const getFlavours = async()=>{
      const dataflavours = await getDocs(flavorsCollectionRef);
      // console.log(data.docs.map((doc)=>({
      //     ...doc.data(),id:doc.id
      // })));
      setFlavours(dataflavours.docs.map((doc)=>({
        ...doc.data(),id:doc.id
      })).sort((a, b) => a.flavour_order - b.flavour_order));
  
  }





  const sectionRef = useRef(null);
  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  React.useEffect(()=>{
    
      getFlavours()
      getCombos()
      getWingsCombos()
      getPartyPacks()
      getBurgerMenu()
      // console.log(burgerMenu)
      getKidsMeal()
      getAppsSides()
      // console.log("apps and sides", sides[0].item_price.large)
      getDesserts()
      getDips()
      getDrinks()
      if (window.location.hash === "#section") {
        sectionRef.current.scrollIntoView();
      }
      else if(window.location.hash === "#section2"){
        sectionRef1.current.scrollIntoView();
      }
      else if(window.location.hash === "#section3"){
        sectionRef2.current.scrollIntoView();
      }
      
  },[])


  const wet_flavours = flavours.map(item => {
    
    return(
        item.flavour_type === "wet" ?
        <FlavourCard
          key = {item.id}
          flavourname = {item.flavour_name}
          flavourcolor = {item.flavour_color}
        /> 
        : ""
      )
    

  })

  const dry_flavours = flavours.map(item => {
    
    return(
        item.flavour_type === "dry" ?
        <FlavourCard
          key = {item.id}
          flavourname = {item.flavour_name}
          flavourcolor = {item.flavour_color}
        /> 
        : ""
      )
  })

  const both_combos = combos.map(item =>{
    return(
     item.combo_type.includes("boneless") && item.combo_type.includes("bone-in") ? 
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "combos"
      />
      : ""
      
    )
  })
  const boneless_combos = combos.map(item =>{
    return(
     item.combo_type.includes("boneless") && item.combo_type.length === 1? 
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "combos"
      />: ""
      
    )
  })
  const bonein_combos = combos.map(item =>{
    return(
     item.combo_type.includes("bone-in")  && item.combo_type.length === 1?
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "combos"
      />: ""
      
    )
  })

  const both_wings = wingscombos.map(item=>{
    return(
      item.combo_type.includes("boneless") && item.combo_type.includes("bone-in")? 
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "wings"
      />: ""
    )
  })

  const boneless_wings = wingscombos.map(item=>{
    return(
      item.combo_type.includes("boneless") && !item.combo_type.includes("bone-in") ?
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "wings"
      />
      : ""
    )
  })

  const bonein_wings = wingscombos.map(item=>{
    return(
      item.combo_type.includes("bone-in") && !item.combo_type.includes("boneless") ?
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "wings"
      />
      : ""
    )
  })

  const both_party_packs = partyPacks.map(item=>{
    return(
      item.combo_type.includes("bone-in") && item.combo_type.includes("boneless") ?
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "partypacks"
      />
      : ""

    )
  })

  const bonein_party_packs = partyPacks.map(item=>{
    return(
      item.combo_type.includes("bone-in") && !item.combo_type.includes("boneless") ? 
      <MenuCard 
        key = {item.id}
        price = {item.combo_price}
        type = {item.combo_type}
        description = {item.combo_desc}
        menu_type = "partypacks"
      />
      : ""
    )
  })

  // const boneless_party_packs = partyPacks.map(item=>{
  //   return(
  //     item.combo_type.includes("boneless") && !item.combo_type.includes("bonein") ? 
  //     <MenuCard 
  //       key = {item.id}
  //       price = {item.combo_price}
  //       type = {item.combo_type}
  //       description = {item.combo_desc}
  //       menu_type = "partypacks"
  //     />
  //     : ""
  //   )
  // })

  const burgermenu = burgerMenu.map(item => {
    return(
      <MenuCard 
        key = {item.id}
        img = {item.burger_img}
        type = {item.burger_type}
        name = {item.burger_name}
        description = {item.burger_desc}
        menu_type = "burgers"
      />
    )
  })

  const kidsmeal = kidsMeal.map(item =>{
    return(
      <MenuCard 
        key = {item.id}
        price = {item.meal_price}
        description = {item.meal_desc}
        menu_type = "kidsmeal"
      />
    )
  })

  const appsides = sides.map(item =>{
    return (
      <MenuCard 
        key = {item.id}
        large_price = {item.item_price.large}
        small_price = {item.item_price.small}
        img = {item.item_img}
        description = {item.item_name}
        menu_type = "appsides"
      />
    )
  })
  
  const dessertsmenu = desserts.map(item=>{
    return(
      <MenuCard 
        key = {item.id}
        price = {item.item_price}
        description = {item.item_name}
        img= {item.item_img}
        menu_type = "desserts"
      />
    )
  })

  const dipsmenu = dips.map(item =>{
    return(
      <MenuCard 
        key = {item.id}
        price = {item.item_price}
        amount = {item.item_amount}
        description = {item.item_name}
        img= {item.item_img}
        menu_type = "dips"
      />
    )
  })

  const drinksmenu = drinks.map(item =>{
    return(
      <MenuCard 
        key = {item.id}
        price = {item.item_price}
        description = {item.item_name}
        img={item.item_img}
        menu_type = "drinks"
      />
    )
  })

  const [isVisible, setIsVisible] = useState(false);

  // Add a scroll event listener to check when the user has scrolled
  window.addEventListener("scroll", () => {
    // If the user has scrolled more than 100 pixels, show the button
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  });

  // Handle click event to scroll back to top
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  

  return(

  <div className='gallery-header'  style={{"backgroundColor": "#E3D3CD"}}>
    {isVisible && (
        <button onClick={handleClick} className="scroll-to-top-button">
          <BsFillArrowUpCircleFill fontSize={30}/>
        </button>
      )}
    <h1 className='cta-heading'>
      Choose your wing flavour
    </h1>

      <div className="color-circles">
        <p className="circle-tags tag1">Hot</p>
        <div className="red circle"></div>
        <div className="orange circle"></div>
        <div className="mustard circle"></div>
        <div className="yellow circle"></div>
        <div className="green circle"></div>
        <p className="circle-tags tag2">Mild</p>
      </div>
    {/* <div className="add-button-div">

    <button>
      <BsPlusCircleFill fontSize={30} color="#FF5623"/>
    </button>
    </div> */}
    <h2 className='flavour-type'>WET</h2>

    <div className='flavour-tabs'>
      
      {wet_flavours}

      {/* <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/> */}
    </div>

    <div className='divider'>

    </div>

    <h2 className='flavour-type'>DRY</h2>

    <div className='flavour-tabs'>
      
      {dry_flavours}
      {/* <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/>
      <FlavourCard/> */}
    </div>

    <section id="personalCombos">

    <h1 className="cta-heading">Personal Combos</h1>
    <h2 className="flavour-type">Boneless / Bone-In</h2>

    <div className="combo-slider">
       
      <div className="combo-tabs">
        {both_combos}

      </div>
    </div>
   
    
    {!boneless_combos.every((e)=>{
        return e === ""
    }) &&
    
    <h2 className="flavour-type">Boneless</h2>
    }
    {!boneless_combos.every((e)=>{
        return e === ""
    }) && 
    // <div>
      <div className="combo-slider">
       
      <div className="flavour-tabs combo-tabs">
        {boneless_combos}

      </div>
    </div>
    // </div>
    }



   
    {!bonein_combos.every((e)=>{
        return e === ""
    }) && 
    
    <h2 className="flavour-type">Bone-In</h2>
    }
    {!bonein_combos.every((e)=>{
        return e === ""
    }) && 
    // <div>
      <div className="combo-slider">
       
      <div className="flavour-tabs combo-tabs">
        {bonein_combos}

      </div>
    </div>
    
    
    }
    </section>


    
    <div className="divider"></div>

    <section id='justWings' ref={sectionRef1}>

    <h1 className="cta-heading">Just Wings</h1>

    <h2 className="flavour-type">Boneless / Bone-in</h2>

    {!both_wings.every((e)=>{
        return e === ""
    }) &&
        <div className="combo-slider">
       
        <div className="combo-tabs">
          {both_wings}
  
        </div>
      </div>
    }

    {
      !boneless_wings.every((e)=>{
        return e === ""
    }) &&
    <h2 className="flavour-type">Boneless</h2>
    }

    {
      !boneless_wings.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {boneless_wings}

    </div>
  </div>
    }

   
{
      !bonein_wings.every((e)=>{
        return e === ""
    }) &&
    <h2 className="flavour-type">Bone-In</h2>
    }

    {
      !bonein_wings.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {bonein_wings}

    </div>
  </div>
    }
    </section>
    
    <div className='divider'>

    </div>

    <section id="partyPacks">

    <h1 className="cta-heading">Party Packs</h1>
    <h2 className="flavour-type">Boneless / Bone-In</h2>


    {!both_party_packs.every((e)=>{
        return e === ""
    }) &&
        <div className="combo-slider">
       
        <div className="combo-tabs">
          {both_party_packs}
  
        </div>
      </div>
    }
    


    {
      !bonein_party_packs.every((e)=>{
        return e === ""
    }) &&

    <h2 className="flavour-type">Bone In</h2>
    }

    {
      !bonein_party_packs.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {bonein_party_packs}

    </div>
  </div>
    }
    </section>

  <div className="divider"></div>


    <section id="burgers" ref={sectionRef2}>

  <h1 className="cta-heading">Burgers</h1>
  {
      !burgermenu.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {burgermenu}

    </div>
  </div>
    }
    </section>
<div className='divider'></div>

    <section id="kidsMeal">

<h1 className="cta-heading">Kids Meal</h1>

{
      !kidsmeal.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {kidsmeal}

    </div>
  </div>
    }
    </section>


<div className='divider'></div>

  <section id="appsSides" ref={sectionRef}>

  <h1 className="cta-heading">Appetizers and Sides</h1>
      {/* <h2 className="flavour-type">Boneless</h2> */}

      {
        !appsides.every((e)=>{
          return e === ""
      }) &&
      <div className="combo-slider">
        
      <div className="combo-tabs">
        {appsides}

      </div>
    </div>
      }
  </section>

<div className='divider'></div>

      <section id="desserts">

    <h1 className="cta-heading">Desserts</h1>
    {/* <h2 className="flavour-type">Boneless</h2> */}
    {
      !dessertsmenu.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {dessertsmenu}

    </div>
  </div>
    }
      </section>
    

    <div className='divider'>

</div>

    <section id="dips">

    <h1 className="cta-heading">Dips</h1>
    {/* <h2 className="flavour-type">Boneless</h2> */}

    {
      !dipsmenu.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {dipsmenu}

    </div>
  </div>
    }
    </section>
<div className='divider'>

</div>

    <section id="drinks">

    <h1 className="cta-heading">Drinks</h1>
    {/* <h2 className="flavour-type">Boneless</h2> */}

    {
      !drinksmenu.every((e)=>{
        return e === ""
    }) &&
    <div className="combo-slider">
       
    <div className="combo-tabs">
      {drinksmenu}

    </div>
  </div>
    }
    </section>
    
  </div>
  )
}

export default Gallery;
