import React from 'react';
import { ImageSlide, SubHeading } from '../../../components';
import {FaPepperHot} from 'react-icons/fa'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { useNavigate } from "react-router-dom";

// import {images} from '../../../constants'
import images from '../../../constants/images'
import './Header.css';


const Header = () => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/menu`; 
    navigate(path);
  }
  const newRouteChange = () =>{
    let path = `contact`;
    navigate(path)
  }
  // const [childWidth, setChildWidth] = React.useState(null);
  const parentRef = React.useRef(null);

  // const updateChildWidth = () =>{
  //   const parentWidth = parentRef.current.offsetWidth;
  //   setChildWidth(parentWidth);
  // }

  // React.useEffect(()=>{
  //   updateChildWidth();
  //   window.addEventListener("resize",updateChildWidth);
  //   return () => window.removeEventListener("resize", updateChildWidth);
  // },[])

  const slides = [
    { url: images.slider1, title: "beach" },
    { url: images.slider2, title: "beach" },
    { url: images.slider3, title: "beach" },
    { url: images.slider4, title: "beach" },
    { url: images.slider5, title: "beach" },
    { url: images.slider6, title: "beach" },
    
  ];
  const containerStyles = {
    width: "25rem",
    height: "25rem",
    margin: "0 auto",
    ...(() => {
      const styles = {};
  
      if (window.matchMedia("(max-width: 1100px)").matches) {
        styles.width = "300px";
        styles.height = "400px";
      }
  
      return styles;
    })(),
   
  };
  return(

  <div className='app__header' id='home'>
    <div className='app__wrapper_info'>
    <p className='location-heading'>RiverStone</p>

      <SubHeading title="WINGSTATION"/>
      
      <h1 className='app__header-h1'>...not your ordinary wings <FaPepperHot color="#FF5623"/>
      </h1>
      <p className='app__header-p'> <span className='app__header-span'>100%</span> All Natural and Halal </p>
      {/* <p className='p__tagline' style={{margin:'2rem 0'}}>Hand-spun chicken wings and sides, fresh and hot for pick-up</p>   */}
      <div className="app__header-buttons">

      <button className='app__header-button-1' onClick={routeChange}>Explore Menu <AiOutlineArrowRight /> 
      </button>
      <button className='app__header-button-2' onClick={newRouteChange}>Contact</button>
      </div>
    </div>

      
    <div className='image-slider'>
      <div style={containerStyles} className='image-slider'>
        <ImageSlide slides={slides} parentWidth={400} />
      </div>
    </div>
    {/* Header */}
  </div>
  )
}


export default Header;
