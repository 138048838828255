import React from 'react'
import {Navbar, CTA, MenuCard}  from '../../components'
import Footer from '../HomePage/Footer/Footer'
import FindUs from './Findus/FindUs'

const ContactPage = () => {
  return (
    <div>
      <Navbar/>
      <FindUs/>
      <Footer page="contact"/>
    </div>
  )
}

export default ContactPage
