import React from 'react'
import './Cta.css'
import images from '../../constants/images'
import { useNavigate } from "react-router-dom";



const Cta = () => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `menu`; 
    navigate(path);
  }

  
  return (
    <div className='outer-cta-div'>
      <div className='outer-left'>
            <h1>Find the newest and hottest flavours of chicken wings in the menu!</h1>

            <button className='cta-button' onClick={routeChange}>Explore Menu 
            {/* <AiOutlineArrowDown fontSize={20} /> */}
            </button>
      </div>

      <div className='outer-right'>
        <img src={images.menupageheader} alt="" />
      </div>
    </div>
  )
}

export default Cta
