import React from 'react';
import {GiHamburgerMenu} from 'react-icons/gi';
import {MdOutlineRestaurantMenu} from 'react-icons/md'
import { Link, useMatch, useResolvedPath, useNavigate } from "react-router-dom";


import images from '../../constants/images'
import './Navbar.css'

import './Navbar.css';


const Navbar = () => {
  let navigate = useNavigate(); 
  const [toggleMenu, setToggleMenu] = React.useState(false)
  const gotoHome = () =>{ 
    let path = `/`; 
    navigate(path);
  }
  
  const orderOnline = () =>{
    window.open("https://www.toasttab.com/wing-station-riverstone/v3?utm_source=google&utm_medium=organic&utm_campaign=gmb-oo-web#!%20/", "_blank");    
  }

  return (
  <nav className='app__navbar'>
    
        
    <div className='app__navbar-logo' onClick={gotoHome}>
      <img src={images.logo} alt="wingslogo" />
      {/* <h1>WingStation</h1> */}
      
    </div>
   
      <ul className='app__navbar-links'>
        <CustomLink to="/" className="custom-link">Home</CustomLink>
        <CustomLink to="/menu" className="custom-link">Menu</CustomLink>
        <CustomLink to="/contact" className="custom-link">Contact</CustomLink>
       
      </ul>

    <button className='app__navbar-button' onClick={orderOnline}>Order Online</button>
   
   <div className="app__navbar-smallscreen">
    <GiHamburgerMenu color="#FF5623" fontSize={27} className="overlay__open" onClick={()=>setToggleMenu(true)}/>

    {toggleMenu &&(
      <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
      <MdOutlineRestaurantMenu color="#FF5623" fontSize={27} className="overlay__close" onClick={()=>setToggleMenu(false)}/>

      <ul className='app__navbar-smallscreen_links'>
        <CustomLink to="/">Home</CustomLink>
        <CustomLink to="/menu">Menu</CustomLink>
        <CustomLink to="/contact">Contact</CustomLink>
        <button className='app__navbar-smallscreen_button' onClick={orderOnline}> 
          Order Online
          </button>
       
      </ul>
    </div>
    )}
    
    
   </div>
    {/* Navbar */}
  </nav>
  );
}
function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "p__opensans active" : "p__opensans"}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
export default Navbar;
